import React, { useEffect, useState, useCallback } from "react";
import "./Arqueologico.scss";
import { useParams } from "react-router-dom";
import PriceCard from "../../../../components/PriceCard/PriceCard";
import { API, graphqlOperation } from "aws-amplify";
import { getTour } from "../../../../graphql/queries";
import { toursIDs } from "../../../../data/toursIDs";
import { dataTourFormat } from "../../../../utils/helpers";

const Arqueologico = () => {
  const { place } = useParams();
  const [tourData, setTourData] = useState();

  const fetchTour = useCallback(async () => {
    const response = await API.graphql(
      graphqlOperation(getTour, {
        id: toursIDs.tourArqueologico,
      })
    );
    const data = dataTourFormat(response.data.getTour, place);

    setTourData(data);
  }, [place]);

  useEffect(() => {
    const evt = new Event("DOMContentLoaded", {
      bubbles: true,
      cancelable: false,
    });
    document.dispatchEvent(evt);
  });

  useEffect(() => {
    fetchTour();
  }, [fetchTour]);

  return (
    <div className="arqueologico">
      <div
        className={`header-arqueologico bg-cover bg-center rounded-br-3xl flex justify-center items-center`}
      >
        <h1 className="text-white font-bold text-[50px]">Tour Arqueologico</h1>
      </div>
      <div className="md:mx-48 flex md:flex-row flex-col">
        <div className="flex m-5 flex-col">
          {/* Información */}
          <div className="flex flex-col m-7">
            <div className="mt-3">
              <p className="text-[20px] text-left text-white">
                Visitamos una Zona Arqueologia de Tamtoc (lugar de nubes) Es un
                asentamiento de la cultura Huasteca mas importantes del ultimo
                periodo prehispanico, Ademas esta zona arqueologica destaca por
                sus piramides, monolitos, estelas y esculturas como la Venus de
                tamtoc, que demuestra la importancia que la mujer tuvo dentro de
                su sociedad. Posteriosmente visitamos el Nacimiento de Taninul
                en Ciudad Valles aguas cristalinas y sobre el nacimiento pasan
                las vias ferroviarias. Como opcion es la zona arqueologica de
                tamohi un lugar prehispanico con palacios, edificios y altares
                con extraordinarias pinturas morales.
              </p>
            </div>
            <div className="flex justify-between mt-3">
              <img
                src="assets/images/tours/arqueologico/image1.jpg"
                alt=""
                className="h-3/2 w-1/4 rounded-2xl m-4"
              />
              <img
                src="assets/images/tours/arqueologico/arqueologico.jpg"
                alt=""
                className="h-3/2 w-1/4 rounded-2xl m-4"
              />
              <img
                src="assets/images/tours/arqueologico/image2.jpeg"
                alt=""
                className="h-3/2 w-1/4 rounded-2xl m-4"
              />
            </div>
          </div>
          {/* info */}
          <div className="mt-3">
            <h1 className="text-3xl text-white font-semibold">¿Que incluye?</h1>
            <div className="flex justify-between mt-3 text-white text-xl">
              <div className="leading-9">
                <p>🔘Desayuno o comida de platillos tipicos.</p>
                <p>🔘Transporte desde tu hospedaje.</p>
                <p>🔘Equipo de seguridad requerido</p>
              </div>
              <div className="leading-9">
                <p>🔘Entradas a los parques.</p>
                <p>🔘Paseo en canoa.</p>
                <p>🔘Guia especializado.</p>
              </div>
              <div className="leading-9">
                <p>🔘Fotografias.</p>
                <p>🔘Recorrido guiado.</p>
                <p>🔘Botiquin P.A.</p>
              </div>
            </div>
          </div>
          {/* que llevar */}
          <div className="mt-8 mb-0">
            <img
              src="assets/images/tours/arqueologico/banner1.jpeg"
              alt=""
              className="w-full md:h-[400px] h-[200px]"
            />

            <div className="mt-8">
              <h1 className="text-white text-3xl font-semibold">
                ¿Que debes llevar?
              </h1>
              <div className="text-white text-xl leading-8 mt-3">
                <p>
                  🔶Acudir con ropa comoda para caminar puede ser pans, licra,
                  short pantalon de senderismo asi como ropa para meterse al
                  agua (traje de baño).
                </p>
                <p>🔶Zapato acuatico y tenis comodos antiderrapante.</p>
                <p>🔶Bloqueador solar (de preferencia biodegradable)</p>
                <p>🔶Repelente (biodegradable).</p>
                <p>🔶Toalla.</p>
                <p>🔶En caso de mareo Dramamine.</p>
                <p>🔶Cuidar nuestra Flora y Fauna</p>
                <p>🔶Respete las recomendaciones del guia.</p>
                <p>
                  🔶Respete a los de mas compañeros mantenimiento silencio
                  durante las explicaciones y contestando tu telefono lejos del
                  grupo si hacerlo puede interrumpir explicaciones.
                </p>
                <p>
                  🔶Respete los tiempos establecidos, estos estan pensafos para
                  hacer grata la experiencia para todos los participantes de la
                  actividad.
                </p>
                <p>
                  🔶Si va con niños recuerde que ellos son su responsabilidad.
                </p>
                <p className="pt-7 pb-0">
                  🔴LA BASURA NO VUELVE SOLA ES PARTE DE SU EQUIPAJE, LLEVELA
                  SIEMPRE CONSIGO HASTA LLEGAR CASA, NO ENSUCIE CAMINOS, RIOS,
                  BOSQUES, CUIDE LA NATURALEZA. 🌿🍃
                </p>
              </div>
            </div>
          </div>
          {/* importante */}
          <div className="mt-3">
            <img
              src="assets/images/tours/arqueologico/banner2.jpeg"
              alt=""
              className="w-full md:h-[400px] h-[200px]"
            />
            <div className="mt-8">
              <h1 className="text-white text-3xl font-semibold">IMPORTANTE</h1>
              <div className="text-white text-xl mt-3">
                <p>
                  🔘Sorprendente Tour te proporciona un guia acreditado y todo
                  el equipo necesario para desarrollar cada una de las
                  actividades.
                </p>
                <p>
                  🔘La huasteca tiene ciclos metereologicos que hacen que los
                  niveles de agua en cada paraje pueden aumentar, nuestra
                  empresa no es responsable si algun paraje cierra sus puertas
                  al publico por los niveles de agua altos, mas sin embargo
                  compensaremos con alguna otra actividad.
                </p>
                <p>
                  🔘Algunos trayectos seran largos de 1 a 3 horas e incluso un
                  poco mas donde se le notificara para que tome ciertas medidas
                  en cuestiones personales (medicamentos, mareos, etc).
                </p>
                <p>
                  🔘Contar con algo de dinero en efectivo, ya que no hay bancos
                  ni cajeros automaticos en los lugares a visitar.
                </p>
              </div>
            </div>
          </div>
          {/* itinerario */}
          <div className="mt-3">
            <img
              src="assets/images/tours/arqueologico/banner3.jpeg"
              alt=""
              className="w-full md:h-[400px] h-[200px]"
            />
            <div className="mt-8">
              <h1 className="text-white text-3xl font-semibold">
                Itinerario del dia
              </h1>
              <div className="text-white text-xl mt-3">
                <p>7:40 am a 8:00 am Punto de reunion o traslado de su hotel</p>
                <p>8:00 am a 9:00 am Desayuno.</p>
                <p>9:00 am a 11:00 am Trayecto a tamtoc.</p>
                <p>11:00 am a 1:30 pm Recorrido a tamtoc.</p>
                <p>1:30 pm a 2:00 pm Trayeto al nacimiento de Taninul.</p>
                <p>
                  2:00 pm a 4:00 pm Tiempo para hacer actividad acuatica en
                  Taninul.
                </p>
                <p>4:00 pm a 5:00 pm Comida.</p>
                <p>5:00 pm Regreso a CD valles o Xilitla.</p>
              </div>
            </div>
          </div>
          {/* precio */}
          <div className="mt-3">
            <img
              src="assets/images/tours/arqueologico/banner4.jpeg"
              alt=""
              className="w-full md:h-[400px] h-[200px]"
            />
            <div className="mt-8">
              <h1 className="text-white text-3xl font-semibold">Precio</h1>
              <div className="text-white text-xl mt-4 md:mt-0 md:flex-row flex md:justify-between flex-col md:items-center">
                <div>
                  <h2 className="text-3xl font-bold">Xilitla</h2>
                  <h3 className="text-2xl">$1,400 por persona</h3>
                </div>
                <div className="h-40 w-36 bg-lime-600 flex justify-center items-center rounded-2xl my-5 md:my-0">
                  <p className="text-center text-black font-semibold">
                    Pregunta por nuestros paquetes Todo Incluido!
                  </p>
                </div>
                <div>
                  <h2 className="text-3xl font-bold">Cd. Valles</h2>
                  <h3 className="text-2xl">$1,300 por persona</h3>
                </div>
              </div>
            </div>
          </div>
          {/* ruta */}
          {place === "xilitla" && <section className="m-5"></section>}
        </div>
        {tourData && <PriceCard tour={tourData} />}
      </div>
    </div>
  );
};

export default Arqueologico;
